var render = function render(){
  var _vm$userReceivingGift, _vm$userReceivingGift2, _vm$userReceivingGift10, _vm$userReceivingGift11, _vm$userReceivingGift12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "subscription-form"
    }
  }, [_c('div', {
    staticClass: "w-100 h-100",
    class: {
      'mb-2': (_vm$userReceivingGift = _vm.userReceivingGift) === null || _vm$userReceivingGift === void 0 ? void 0 : _vm$userReceivingGift._id
    }
  }, [(_vm$userReceivingGift2 = _vm.userReceivingGift) !== null && _vm$userReceivingGift2 !== void 0 && _vm$userReceivingGift2._id ? _c('strong', {
    staticClass: "text-center d-block mb-3 mx-5"
  }, [_vm._v(" " + _vm._s(_vm.$t('giftSubscriptionLeadText')) + " ")]) : _vm._e(), _vm._l(_vm.subscriptionBlocksOrdered, function (block) {
    var _vm$userReceivingGift3, _vm$userReceivingGift4, _vm$userReceivingGift5, _vm$userReceivingGift6, _vm$userReceivingGift7, _vm$userReceivingGift8, _vm$userReceivingGift9, _vm$user, _vm$user$purchased, _vm$user$purchased$pl;
    return block.target !== 'group' && block.canSubscribe === true ? _c('div', {
      key: block.key,
      staticClass: "subscribe-option d-flex",
      class: {
        selected: _vm.subscription.key === block.key,
        'mb-2': block.months !== 12,
        final: block.months === 12,
        'mx-4': (_vm$userReceivingGift3 = _vm.userReceivingGift) === null || _vm$userReceivingGift3 === void 0 ? void 0 : _vm$userReceivingGift3._id
      },
      attrs: {
        "value": block.key
      },
      on: {
        "click": function click($event) {
          return _vm.updateSubscriptionData(block.key);
        }
      }
    }, [_vm.subscription.key === block.key ? _c('div', [_c('div', {
      staticClass: "selected-corner"
    }), _c('div', {
      staticClass: "svg svg-icon svg-check color m-2",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.check)
      }
    })]) : _vm._e(), block.months === 12 ? _c('div', {
      staticClass: "ribbon mt-3 d-flex align-items-center"
    }, [_c('small', {
      staticClass: "bold teal-1"
    }, [_vm._v(" " + _vm._s(_vm.$t('popular')) + " ")])]) : _vm._e(), _c('div', {
      staticClass: "w-100"
    }, [block.months < 12 ? _c('div', {
      staticClass: "mx-5"
    }, [_c('h2', {
      staticClass: "mt-3 mb-1"
    }, [_vm._v(" $" + _vm._s(block.price) + ".00 USD ")]), _c('small', {
      staticClass: "bold mb-2"
    }, [_vm._v(" " + _vm._s(_vm.recurrenceText(block.months)) + " ")]), _c('div', {
      staticClass: "d-flex flex-column mb-3"
    }, [_c('div', {
      staticClass: "d-flex align-items-center mb-1"
    }, [_c('div', {
      staticClass: "svg svg-icon svg-plus color mr-1",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.plus)
      }
    }), _c('small', {
      domProps: {
        "innerHTML": _vm._s((_vm$userReceivingGift4 = _vm.userReceivingGift) !== null && _vm$userReceivingGift4 !== void 0 && _vm$userReceivingGift4._id ? _vm.$t('unlockNGemsGift', {
          count: 24
        }) : _vm.$t('unlockNGems', {
          count: 24
        }))
      }
    })]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "svg svg-icon svg-plus color mr-1",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.plus)
      }
    }), _c('small', {
      domProps: {
        "innerHTML": _vm._s((_vm$userReceivingGift5 = _vm.userReceivingGift) !== null && _vm$userReceivingGift5 !== void 0 && _vm$userReceivingGift5._id ? _vm.$t('earn2GemsGift') : _vm.$t('earn2Gems'))
      }
    })])])]) : _c('div', [_c('div', {
      staticClass: "bg-white py-3 pl-5",
      class: {
        round: (_vm$userReceivingGift6 = _vm.userReceivingGift) === null || _vm$userReceivingGift6 === void 0 ? void 0 : _vm$userReceivingGift6._id
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center mb-1"
    }, [_c('h2', {
      staticClass: "mr-2 my-auto"
    }, [_vm._v(" $" + _vm._s(block.price) + ".00 USD")]), _c('strike', {
      staticClass: "gray-200"
    }, [_vm._v("$60.00 USD")])], 1), _c('small', {
      staticClass: "bold mb-2"
    }, [_vm._v(" " + _vm._s(_vm.recurrenceText(block.months)) + " ")]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "d-flex align-items-center mb-1"
    }, [_c('div', {
      staticClass: "svg svg-icon svg-plus color mr-1",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.plus)
      }
    }), _c('small', {
      domProps: {
        "innerHTML": _vm._s((_vm$userReceivingGift7 = _vm.userReceivingGift) !== null && _vm$userReceivingGift7 !== void 0 && _vm$userReceivingGift7._id ? _vm.$t('unlockNGemsGift', {
          count: 50
        }) : _vm.$t('unlockNGems', {
          count: 50
        }))
      }
    })]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "svg svg-icon svg-plus color mr-1",
      domProps: {
        "innerHTML": _vm._s(_vm.icons.plus)
      }
    }), _c('small', {
      domProps: {
        "innerHTML": _vm._s((_vm$userReceivingGift8 = _vm.userReceivingGift) !== null && _vm$userReceivingGift8 !== void 0 && _vm$userReceivingGift8._id ? _vm.$t('maxGemCapGift') : _vm.$t('maxGemCap'))
      }
    })])])]), !((_vm$userReceivingGift9 = _vm.userReceivingGift) !== null && _vm$userReceivingGift9 !== void 0 && _vm$userReceivingGift9._id) && !((_vm$user = _vm.user) !== null && _vm$user !== void 0 && (_vm$user$purchased = _vm$user.purchased) !== null && _vm$user$purchased !== void 0 && (_vm$user$purchased$pl = _vm$user$purchased.plan) !== null && _vm$user$purchased$pl !== void 0 && _vm$user$purchased$pl.hourglassPromoReceived) ? _c('div', {
      staticClass: "gradient-banner text-center"
    }, [_c('small', {
      staticClass: "my-3",
      domProps: {
        "innerHTML": _vm._s(_vm.$t('immediate12Hourglasses'))
      }
    })]) : _vm._e()])])]) : _vm._e();
  }), _c('button', {
    staticClass: "btn btn-primary",
    class: [_vm.canceled ? 'mt-4' : 'mt-3', (_vm$userReceivingGift10 = _vm.userReceivingGift) !== null && _vm$userReceivingGift10 !== void 0 && _vm$userReceivingGift10._id ? 'mx-4' : 'w-100'],
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('bv::show::modal', 'buy-subscription');
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$userReceivingGift11 = _vm.userReceivingGift) !== null && _vm$userReceivingGift11 !== void 0 && _vm$userReceivingGift11._id ? _vm.$t('selectPayment') : _vm.$t('subscribe')) + " ")])], 2), _vm.note ? _c('div', {
    staticClass: "mx-4 my-3 text-center"
  }, [_vm._m(0)]) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "buy-subscription",
      "size": "md",
      "hide-header": true,
      "hide-footer": true
    }
  }, [(_vm$userReceivingGift12 = _vm.userReceivingGift) !== null && _vm$userReceivingGift12 !== void 0 && _vm$userReceivingGift12._id ? _c('payments-buttons', {
    attrs: {
      "disabled": !_vm.subscription.key,
      "stripe-fn": function stripeFn() {
        return _vm.redirectToStripe({
          gift: _vm.gift,
          uuid: _vm.userReceivingGift._id,
          receiverName: _vm.receiverName
        });
      },
      "paypal-fn": function paypalFn() {
        return _vm.openPaypalGift({
          gift: _vm.gift,
          giftedTo: _vm.userReceivingGift._id,
          receiverName: _vm.receiverName
        });
      }
    }
  }) : _c('payments-buttons', {
    attrs: {
      "disabled": !_vm.subscription.key,
      "stripe-fn": function stripeFn() {
        return _vm.redirectToStripe({
          subscription: _vm.subscription.key,
          coupon: _vm.subscription.coupon
        });
      },
      "paypal-fn": function paypalFn() {
        return _vm.openPaypal({
          url: _vm.paypalPurchaseLink,
          type: 'subscription'
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', {
    staticClass: "font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.note)) + " ")]);

}]

export { render, staticRenderFns }